<template>
  <div class='py-2 lg:py-8 px-4 lg:px-0'>
    <div class='message-primary flex flex-col lg:flex-row justify-between items-stretch gap-x-48'>
      <div
        class='background-style text-gray-900 lg:text-white py-4 px-4 lg:py-16 lg:px-16 flex-shrink-0'>
        <h2 class='text-2xl'>회장 인사말</h2>
      </div>
      <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
        <div class='whitespace-pre-wrap leading-6 max-w-xl text-sm'
          style='color: #686074;'>
          <p class='mb-4'>제55차 춘계학술대회에 참가하신 대한비만학회 회원 및 비만 연구자 여러분 안녕하십니까?</p>
          <p class='mb-4'>어려운 여건에도 불구하고 이번 학술대회에 참가 해주신 모든 분들께 환영과 감사의 말씀을 드립니다. 코로나19가 우리 생활의 많은 변화를 가져 왔습니다. 특히 신체적 활동의 기회 감소와 섭취량이 증가 되면서 비만 유병률은 더욱 악화일로에 있으며, 새로운 팬데믹의 위험성을 가져다 주고 있습니다. 그렇지만 짙은 겨울이 지난 후 새로운 봄이 활짝 열린 것처럼 머지않아 곧 일상으로 돌아갈 수 있기를 기대합니다. 이제 우리는 엔데믹과 더불어 비만 인구의 증가에 대비하여 새로운 관심을 준비해야 합니다.</p>
          <p class='mb-4'>대한비만학회는 비만 관련 연구의 최근 지평과 학문적 발전을 위하여 이틀에 걸쳐서 제55차 춘계학술대회 프로그램을 준비하였습니다. 학술대회 첫째 날에는 대한영양사협회와 고도비만환자 진료의 영양 상담 급여화 추진을 위한 공동 심포지엄과 두 차례의 위성 심포지엄을 구성하였으며, 둘째 날에는 ‘Comprehensive Care for People with Obesity’의 대주제 아래 울산의대 박혜순 교수님의 Plenary Lecture를 비롯한 국내, 외 유명학자의 초청 강연, 진료지침, 임상, 소아 비만, 운동 및 행동 치료 등의 각 세부 주제별 심포지엄, 오랄 및 포스터 발표 세션 등을 마련하였습니다. 아울러 최근 흐름을 고려하여 ‘빅데이터 및 디지털 치료’ 세션도 준비하였습니다. </p>
          <p class='mb-4'>이번 학술대회에 귀중한 최신정보를 나누어 주실 모든 초청 연자님과 각 세션의 사회 및 좌장님 여러분께 특별한 감사의 말씀을 드리며, 아울러 오랄 및 포스터 발표 세션에 함께 해주신 모든 분들께도 깊은 감사를 드립니다. 이번 춘계학술대회가 참가하신 모든 분들의 학문적 정진과 함께 국내 비만 분야 연구의 진일보에 도움을 주는 소중한 기회가 될 수 있기를 확신합니다. 춘계학술대회 준비를 위해서 수고해 주신 이창범 이사장님과 학술위원장 임 수 교수님을 비롯한 학회 임원 여러분, 사무국 간사 여러분들께도 깊은 감사를 드립니다. </p>
          <p class='mb-4'>그럼 꽃피는 아름다운 봄의 3월, 많은 분들과 함께하는 춘계학술대회에서 뵙기를 소망합니다.</p>
          <p class='mb-4'>아무쪼록 춘계학술대회에 참가 해주신 모든 분들의 즐겁고 소중한 시간이 되시길 바라며, 더욱 건승하시기를 기원합니다.</p>
          <p class='mb-4'>감사합니다.</p>
        </div>
        <div class='font-medium' style='color: #351761;'>
          김기진
        </div>
        <div style='color: #BBB4C6;'>
          대한비만학회 회장
        </div>
        <img class='message-author' src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/%E1%84%92%E1%85%AC%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%82%E1%85%B5%E1%86%B7.jpg' />
      </div>
    </div>
    <div class='message-secondary flex flex-col lg:flex-row justify-between items-stretch gap-x-48'>
      <div
        class='background-style text-gray-900 lg:text-white py-4 px-4 lg:py-16 lg:px-16 flex-shrink-0'>
        <h2 class='text-2xl'>이사장님 인사말</h2>
      </div>
      <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
        <div class='whitespace-pre-wrap leading-6 max-w-xl text-sm'
          style='color: #50584F;'>
          <p>대한비만학회 회원 여러분</p>
          <p class='mb-4'>전국 각지에서 비만연구에 매진하시는 연구자님,</p>
          <p class='mb-4'>
            지속되고 있는 COVID-19 상황에서 힘든 시간을 이겨내고 계시는 연구자님들의 헌신과 노고에 깊은 감사를 드립니다. 
            대한비만학회는 감염병 대유행을 막기위한 사회적 거리두기가 비만과 대사증후군에 미치는 부정적인 생리 변화를 이미 연구 보고하였고, 현재 이에 대한 연구가 진행되고 있습니다.
          </p>
          <p class='mb-4'>올해, 대한비만학회는 창립 30주년을 맞게 됩니다. 
            30년이란 짧고도 긴 세월 속에 어려움도 많았지만, 회원들의 열정과 사랑은 모든 시련을 이겨내게 했고 학회의 위상은 나날이 높여가고 있습니다.
            현 임원진은 정기학술대회를 연 4회 하고 있어, 지금도 올 한 해의 행사를 위해 쉬지 않고 준비하고 있습니다. 
            이번 춘계학술대회는 서울 워커힐호텔에서, Obesity Summer 학술대회는 대구경북지회와 대구에서, ICOMES 국제학술대회는 서울 여의도에서, 그리고 Obesity Winter 학술대회는 대전충남지회와 대전에서 여러분을 찾아가겠습니다. 
          </p>
          <p class='mb-4'>이번 춘계학술대회의 주제는 Comprehensive care for people with diabetes 입니다. 
            비만은 포괄적 접근이 필요한 만성 질환입니다. 
            의학, 영양, 체육, 행동요법 등 여러 분야의 전문적인 접근이 필요하며, 이들을 연결하는 포괄적인 치료는 현대의학의 매우 어려운 과제입니다. 그러므로 많은 연구자들의 네트워크와 밀접한 토론은 비만이라는 학문의 필수요소이기에 학술대회의 의미는 크다고 하겠습니다.
          </p>
          <p class='mb-4'>
            학술대회는 학문의 큰 행사입니다. 
            연구자는 연구성과 를 보여주고 서로 만나 토론하며, 동시에 기업고객사도 함께 참여하여 홍보하는 학술공동체의 페스티벌입니다. 
            기업고객사의 적극적인 참여로, 프로그램에 비만치료약제의 사용에 대한 강의도 다양하게 펼쳐져 있습니다.
          </p>
          <p class='mb-4'>
            COVID-19 방역기준으로 강의실 안은 방역기준에 맞춰 떨어진 거리에 앉게 됩니다.
            하지만 참가한 참석자들의 열기를 강의실 안에서 가깝게 느낄 수 있도록 여러분의 많은 참여와 성원을 바랍니다.
          </p>
          <p class='mb-4'>감사합니다.</p>
        </div>
        <div class='font-medium' style='color: #185F50;'>
          이창범
        </div>
        <div style='color: #B8C6B4;'>
          대한비만학회 이사장
        </div>
        <img class='message-author' src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/%E1%84%8B%E1%85%B5%E1%84%89%E1%85%A1%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%82%E1%85%B5%E1%86%B72.jpg' />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  components: {
  },
}
</script>

<style lang='scss' scoped>

.background-style {
  background: transparent; 
  width: 100%;
}
  
.background-style img {
  position: static;
  right: 1rem; 
  bottom: 0rem; 
  width: 120px;
}
    
.message-author {
  position: absolute;
  right: 1rem; 
  bottom: 1rem; 
  width: 5rem;
}

  @media (min-width: 1024px) {
    .background-style {
      width: 26rem;
      height: auto;
    }
    
    .message-primary .background-style {
      background: linear-gradient(151.84deg, #69449E 10.82%, #32145E 98.95%); 
    }

    .message-secondary .background-style {
      background: linear-gradient(151.84deg, #33AE94 10.82%, #17866F 98.95%); 
    }

    .message-author {
      position: absolute;
      left: -20rem; 
      top: 4rem; 
      width: 180px;
      display: block;
    }

  .background-style img {
    position: absolute;
    right: -4rem; 
    top: 4rem; 
    width: 180px;
    display: block;
  }

}
</style>


